.sideBar.Open {
  transform: none;
}


.sideBarLayout .pinBox {
  bottom: calc(100% / 4);
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  right: 0;
  width: 45px;

}

.videoBox:hover .pinBox {
  opacity: 1;
}

.videoBox .pinBoxVideo {
  opacity: 0;
  width: max-content;
  /* bottom: calc(100% / 2);
  right: calc(100% / 2); */
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  position: absolute;

}

.videoBox:hover .pinBoxVideo {
  opacity: 1;
}

.sideBarLayout .pinBoxVideo {
  width: max-content;
  height: max-content;
  bottom: calc(100% / 3.5);
  right: calc(100% / 3);
  position: absolute;
}


body>#root {
  overflow: hidden;
  /* display: flex; */
  /* height: 100; */
  width: 100%;
  font-family: Montserrat, serif !important;
}

iframe {
  display: none;
}



.ant-btn.ant-btn-default.ant-btn-compact-item.ant-btn-compact-first-item {
  display: none !important;
  ;
}

.ant-btn-icon-only.ant-btn-compact-item {
  background-color: #1a576900 !important;
  border: 1px solid #1a576900 !important;
  color: white !important;
}

.ant-btn.ant-btn-default.ant-btn-icon-only.ant-btn-compact-item.ant-btn-compact-last-item.ant-dropdown-trigger {
  border: none !important;
  ;
}

.custom-select-parent-gray {
  display: flex;
  align-items: center;
  justify-content: center;
  background: gray;
  padding: 5px;
  border-radius: 50px;
}

.custom-select-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a5669;
  padding: 5px;
  border-radius: 50px;
}

.custom-select {
  height: 22px;
  width: 22px;
}

@media (max-width: 915px) {

  .custom-select-parent , .custom-select-parent-gray{
    padding: 2px;
  }
  .in-call{
    margin-top: 0.15rem;
    padding: 5px;
  }
  .custom-select {
    height: 18px;
    width: 18px;
  }
}


@media (max-width: 600px) {
  .custom-select-parent , .custom-select-parent-gray{
    padding: 2px;
  }
  .in-call {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 2px;
  }

  .custom-select {
    height: 18px;
    width: 18px;
  }
}

/* Media query for screens with a maximum width of 480px */
@media (max-width: 400px) {
  .in-call {
    margin-top: 1px;
    width: 32px;
    height: 30px;
    padding: 0px;
  }

  .custom-select {
    height: 16px;
    width: 16px;
  }
}