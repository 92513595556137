.main-container:hover .NameTag {
    display: block;
    color: rgb(255, 255, 255);
    position: absolute;
}

/* .NameEmailTag{
     margin-left: 13px !important;
     width: calc(100vw - 52.4%) !important ; 
}
.NameTag{
     font-size: 12px !important; 
}
.EmailTag{
    
     top: 16% !important; 
}  */


@media screen and (max-width: 450px) {
    .approvalMemberRow {
        align-items: flex-start !important;
        flex-direction: column !important;
        justify-content: baseline !important;
    }
}

@media screen and (max-width: 800px) {
    .MobileSidebarmenuIcon {
        height: 20px;
    }
}
@media screen and (max-width: 300px) {
    .MobileSidebarmenuIcon {
        height: 15px;
    }
}
@media screen and (max-width: 600px) {
    .MobileSidebarmenuIcon {
        height: 18px;
    }
}

.ant-modal-body {

    padding: 10px 20px 10px 20px !important;
    /* color: rgb(250, 250, 250) !important; */
}

.ant-modal-header {

    padding: 10px 20px 10px 20px !important;
    /* background-color: rgba(0, 0, 0, 0.2) !important; */
    border-bottom: none !important;
    border-radius: 10px !important;
    color: rgb(145, 55, 55) !important;
}

.ant-modal-footer {
    padding: 10px 20px 10px 20px !important;
    /* background-color: rgba(0, 0, 0, 0.2) !important; */
    /* border-top: none !important; */
}

.ant-modal-content {
    border-radius: 5px !important;
    /* background-color: rgba(0, 0, 0, 0.2) !important; */
    /* border-bottom: none !important; */
}

.ant-btn-primary {
    background: rgb(26, 86, 105) !important;
    border-color: rgb(26, 86, 105) !important;
}

.ant-btn-primary:hover {
    background: rgb(255, 255, 255) !important;
    border-color: rgb(248, 248, 248) !important;
}

.ant-btn {
    border-radius: 5px !important;
}

.ant-btn:hover {
    color: rgb(26, 86, 105) !important;
    border-color: rgb(26, 86, 105) !important;
}